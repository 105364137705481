<template>
  <div class="smb-client-wrapper">
    <Loader v-if="false" />
    <SmbTitles is-show-title is-show-sub-title title="Personal information" />
    <div class="form-wrapper">
      <v-form v-model="validate" ref="form" lazy-validation>
        <div class="control">
          <div class="control__label">First name</div>
          <SmbInput
            :valueProp="firstName"
            placeholder="First name"
            @propWasUpdated="firstName = $event"
            :rules="[rules.required]"
          />
        </div>

        <div class="control">
          <div class="control__label">Email</div>
          <SmbInput
            :valueProp="email"
            placeholder="Add a valid email"
            @propWasUpdated="email = $event"
            :rules="[rules.required, rules.email]"
          />
        </div>

        <div class="control">
          <div class="control__label">Phone number</div>
          <SmbInput
            :valueProp="phoneNumber"
            placeholder="Add a valid US phone number"
            @propWasUpdated="phoneNumber = $event"
            type="number"
            prefix="+1"
            :rules="[rules.required, rules.phone, rules.phoneLength]"
          />
        </div>
      </v-form>
    </div>
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isSaveBtnLoading"
        :text="isProfileChanged ? 'Save' : 'Cancel'"
        :emitName="isProfileChanged ? 'save' : 'cancel'"
        @cancel="$router.push({ name: 'profile-settings' })"
        @save="saveHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Loader from "@/components/global/Loader";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbInput from "@/components/smb-client/SmbInput";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";

import formValidationMixin from "@/shared/validationRules";

export default {
  name: "SmbCustomerInfoSetting",
  data: () => ({
    email: "",
    phoneNumber: "",
    firstName: "",
    validate: false,
    isSaveBtnLoading: false,
  }),
  mixins: [formValidationMixin],
  components: {
    Loader,
    SmbTitles,
    SmbInput,
    SmbActionBtn,
  },
  created() {
    this.setFieldsValue();
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isProfileChanged() {
      return (
        (this.email !== this.getCurrentSmbCustomer.mail ||
          this.firstName !== this.getCurrentSmbCustomer.personal_name ||
          "1" + this.phoneNumber !==
            this.getCurrentSmbCustomer.primary_phone) &&
        this.validate
      );
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),

    setFieldsValue() {
      this.email = this.getCurrentSmbCustomer.mail;
      this.firstName = this.getCurrentSmbCustomer.personal_name;

      if (
        this.getCurrentSmbCustomer.primary_phone &&
        this.isPhoneNumberStartWithOne(this.getCurrentSmbCustomer.primary_phone)
      ) {
        this.phoneNumber = this.deleteFromStringByIndex(
          this.getCurrentSmbCustomer.primary_phone,
          1
        );
      }
    },
    async saveHandler() {
      this.isSaveBtnLoading = true;
      await this.updateCustomer({
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          primary_phone:
            this.phoneNumber !== this.getCurrentSmbCustomer.primary_phone
              ? "1" + this.phoneNumber
              : undefined,
          mail:
            this.email !== this.getCurrentSmbCustomer.mail
              ? this.email
              : undefined,
          personal_name:
            this.firstName !== this.getCurrentSmbCustomer.firstName
              ? this.firstName
              : undefined,
        },
      });
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.setFieldsValue();
      this.isSaveBtnLoading = false;
      await this.$router.push({ name: "profile-settings" });
    },
    isPhoneNumberStartWithOne(phoneNumber) {
      return phoneNumber.startsWith("1");
    },
    deleteFromStringByIndex(string, indexForRemove) {
      return string.substring(indexForRemove);
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;

  .form-wrapper {
    padding: 21px 20px 10px 20px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and (min-width: 760px) {
      flex: 0;
    }
    .control {
      display: flex;
      flex: 0;
      flex-direction: column;
      &__label {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
      }
      .v-input {
        ::v-deep {
          .v-input__slot {
            min-height: 53px;
            fieldset {
              border: 1px solid #c7c9ca !important;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    padding: 0;
    @media screen and (min-width: 760px) {
      margin-top: 50px;
      max-width: 500px;
      padding: 0 20px 10px 20px;
      justify-content: flex-end;
    }
    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 313px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      @media screen and (min-width: 760px) {
        display: none;
      }
    }
  }
}
</style>
